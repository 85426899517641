import { map, pipe, reverse } from 'ramda';
import { defineStore } from 'pinia';
import {
  emptyCart,
  isSupportLocal,
  getLocalCart,
  setLocal,
  overWriteLocalItems,
  removeLocalCartItem,
  clearLocalCart,
  addToLocalCart,
  getHasLocalCartMerged,
  setHasLocalCartMerged,
} from '@/helper/cart/local-cart';
import callMergeCartApi from '@/api/cart/multi-item-add-cart';
import callApiGetList from '@/api/cart/get-cart-list';
import { isEmptyValue } from '@/helper/data-process';
import fetchCartQuantity from '@/api/cart/get-cart-quantity';
import { cartItemType } from '@/constant/cart/cart-item-type';

// 支援加購品（情境：購物、付款流程失敗，購物車被清空，前端用local cart 併車，恢復購物車）
const buildParameters = function (localCart) {
  const format = map((item) => ({
    product_guid: item.id,
    qty: item.qty,
    is_additional_item: item.isAdditionalItem || false,
    crosssell_items: map((x) => ({ product_guid: x.product_guid, quantity: x.quantity }))(item.crossSellItems || []),
  }));
  return { products: pipe(format, reverse)(localCart.items) };
};

/**
 * 支援全站加購品（情境：購物、付款流程失敗，購物車被清空，前端用local cart 併車，恢復購物車）
 * @param api
 * @param products
 * @returns {*}
 */
const formatProducts = (api, products) => {
  return products.map((product) => {
    const isAdditionalItem = product.item_type === cartItemType.additionalProduct;
    const formattedProduct = {
      id: product.product_guid,
      qty: product.quantity,
      isAdditionalItem: isAdditionalItem || false,
    };

    if (!isAdditionalItem && !isEmptyValue(product.crosssell_items)) {
      const crossSellItems = api === 'cart' ? product.crosssell_items.product_list : product.crosssell_items;
      formattedProduct.crossSellItems = map(
        (item) => ({
          product_guid: item.product_guid,
          name: item.product_name,
          qty: item.quantity,
        }),
        crossSellItems || [],
      );
    }
    return formattedProduct;
  });
};

const state = () => ({
  quantity: null,
  cart: null,
});

const getters = {
  getTotalOfCartItems(state) {
    if (!state.cart) return 0;
    const crossSellsTotalQty = (items) => items.reduce((acc, x) => acc + x.quantity || x.qty, 0);
    return state.cart.items.reduce((acc, item) => acc + item.qty + crossSellsTotalQty(item?.crossSellItems || []), 0);
  },
  getQuantity: (state) => state.quantity || 0,
};

const actions = {
  /**
   * 清空購物車
   * @scope local storage & store
   * @returns {Promise<void>}
   */
  async clearLocalCartAndStore() {
    const cart = await clearLocalCart();
    setHasLocalCartMerged(false);
    this.cart = { ...cart };
  },
  initCart() {
    if (this.cart !== null) {
      return;
    }
    if (!isSupportLocal()) {
      this.cart = { ...emptyCart };
      return;
    }

    /**
     * 使用者未登入
     */
    const hasLocalCartMerged = getHasLocalCartMerged();
    // local 還沒被 merge
    if (!hasLocalCartMerged) {
      this.cart = { ...getLocalCart() };
      return;
    }
    // local 已被 merge，應清空購物車
    this.clearLocalCartAndStore();
  },
  updateLocalCartItemAndStore(item) {
    this.cart = { ...setLocal(item) };
  },
  /**
   * 蓋掉（覆寫）購物車
   * @scope local storage & store
   */
  overwriteLocalItemsAndStore(items) {
    this.cart = { ...overWriteLocalItems(items) };
  },
  /**
   * 刪除「單個」商品
   * @scope local storage & store
   */
  removeLocalCartItemAndStore({ productId, isAdditionalItem }) {
    const cart = removeLocalCartItem(productId, isAdditionalItem);
    if (!cart) return;
    this.cart = { ...cart };
  },
  /**
   * 增加「單個」商品
   * @scope local storage & store
   */
  addToLocalCartAndStore({ productId, quantity, isAdditionalItem = false, crossSellItems }) {
    const cart = addToLocalCart(productId, quantity, isAdditionalItem, crossSellItems);
    this.cart = { ...cart };
  },
  /**
   * 蓋掉（覆寫）購物車 (+formatProducts?)
   * @scope local storage & store
   */
  overwriteLocalCartFromProducts(api = '', products) {
    this.overwriteLocalItemsAndStore(formatProducts(api, products));
  },
  initCartFromLocalAndMergeCartApi() {
    if (!isSupportLocal()) {
      this.cart = { ...emptyCart };
      return false;
    }
    const localCart = getLocalCart();

    // 呼叫 api
    const params = buildParameters(localCart);

    // return promise，被包在 then 裡面使用時，才會 wait promise
    return callMergeCartApi(params, this.$app.$auths.getToken())
      .then((response) => {
        this.overwriteLocalCartFromProducts('merge', response?.data?.cart_items);
        setHasLocalCartMerged(true);
        response = null;
      })
      .then(() => true)
      .catch((error) => {
        console.log('mergeCart === ', error);
        return false;
      });
  },
  /**
   * 如果 remote cart = empty ，將 local cart 併到遠端。
   * @scope local storage & store
   */
  restoreCartIfApiGetCartEmpty(apiToken) {
    return callApiGetList(apiToken).then(async (res) => {
      if (res.data?.cart?.cart_items?.length) return;
      await this.initCartFromLocalAndMergeCartApi();
    });
  },
  /**
   * 呼叫 api 取得 cart quantity
   * @returns {Promise<void>}
   */
  async initCartQuantity() {
    // 未登入一律拿 local cart，這邊不動作。
    if (!this.$app.$auths.isLogin()) {
      this.initCart();
      return;
    }
    // 已經取過資料 return
    if (this.quantity !== null) return;
    // 取資料
    await fetchCartQuantity(this.$app.$auths.getToken())
      .then((response) => {
        this.quantity = response.data?.total_quantity || 0;
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          await this.$app.$auths.logout();
          this.initCart();
        }
      });
  },
  /**
   * 登入狀態呼叫 api 取得 cart quantity，若失敗或未登入則將 local cart 的商品數量，同步到 quantity
   * @scope store
   * @returns {Promise<void>}
   */
  async updateCartQuantity() {
    if (this.$app.$auths.isLogin()) {
      await fetchCartQuantity(this.$app.$auths.getToken())
        .then((response) => {
          this.quantity = response.data?.total_quantity || 0;
        })
        .catch(async (error) => {
          if (error?.response?.status === 401) await this.$app.$auths.logout();
          this.quantity = this.getTotalOfCartItems;
        });
    } else {
      this.quantity = this.getTotalOfCartItems;
    }
  },
  clearQuantity() {
    this.quantity = null;
  },
};

export const useCartItemsStore = defineStore('cartItems', {
  state,
  getters,
  actions,
});
